import React, { useState } from "react";
import "../../css/contact.css";
import NavBar from "../NavBar";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import Itableindicateur from "./Itableindicateur";
import Itableindicateur_2 from "./Itableindicateur_2";
import S_Itableindicateur from "./S_Itableindicateur";
import S_Itableindicateur_2 from "./S_Itableindicateur_2";

const fermerModal = require("../images/fermerModal.png");
const tableicon = require("../images/itable.png");
const educicon = require("../images/educicon.png");
const santeicon = require("../images/educsante.png");

export const Sante = () =>{
  const stl1 = {
    marginBottom:"10px",
    height:"10px"
  }
  const stl2 = {
    display:"flex",justifyContent:"center",paddingBottom:"30px"
  }
  const stl3 =
  {
    width:"80%"
  }
  const fordiv = {
    display:"flex",
    justifyContent:"center",
    backgroundColor:"#081B02",
    marginBottom:"10px",
    height:"30px"
  }
  const fordiv_contain = {
    color:"white",
    fontSize:"20px"
  }


  return(
    <>
     <div style={fordiv} > <p style={fordiv_contain} >Vaccination de routine PEV</p></div><br/>
      <div style={stl2} >
      
      <iframe style={stl3} title="boulel_accespart" height="500" src="https://app.powerbi.com/view?r=eyJrIjoiODJkZTc5YTItZGNhOC00ZWJiLWFhOWMtYjQxNzZhMDg3NDJhIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>
     
      <div style={fordiv} > <p style={fordiv_contain} >Survie de l'enfant</p></div><br/>
      <div style={stl2} >
      
      <iframe style={stl3} title="boulel_accespart" height="500" src="https://app.powerbi.com/view?r=eyJrIjoiZjEwZjI3OGQtMDIxMi00NGEwLTg4YmMtN2VjY2Y2ZjM2NDM1IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"  frameborder="0" allowFullScreen="true"></iframe>
      </div>

      <div style={fordiv} > <p style={fordiv_contain} >sante de la mere</p></div><br/>
      <div style={stl2} >
      
      <iframe style={stl3} title="boulel_accespart" height="500" src="https://app.powerbi.com/view?r=eyJrIjoiMDA5NzcwMDUtNzI4MC00ODg4LWFlODMtOWMyOWIyN2ZlMDY1IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>

      
    </>
  )
}

export const Education = () => {
   
const stl1 = {
  marginBottom:"10px",
  height:"10px"
}
const stl2 = {
  display:"flex",justifyContent:"center",paddingBottom:"30px"
}
const stl3 =
{
  width:"80%"
}
const fordiv = {
  display:"flex",
  justifyContent:"center",
  backgroundColor:"#081B02",
  marginBottom:"10px",
  height:"30px"
}
const fordiv_contain = {
  color:"white",
  fontSize:"20px"
}
  return(
    <>
    <div style={fordiv} > <p style={fordiv_contain} >Acces et participation</p></div><br/>
      <div style={stl2} >
      
      <iframe style={stl3} title="boulel_accespart" height="500" src="https://app.powerbi.com/view?r=eyJrIjoiMmY5YzY1YjktOWQyMi00YTY2LTgyYTEtOGUwOGU0NzBkY2Q2IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>

      <div style={fordiv} > <p style={fordiv_contain} >Qualification</p></div><br/>
      <div style={stl2} >
      
      <iframe style={stl3} title="boulel_accespart" height="500" src="https://app.powerbi.com/view?r=eyJrIjoiZDVlYzE3MzQtNTU3MC00Yjg4LWFlNTAtYTMzMzkwNGNkZTBiIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>

      <div style={fordiv} > <p style={fordiv_contain} >Environnement scolaire</p></div><br/>
      <div style={stl2} >
      
      <iframe style={stl3} title="boulel_accespart" height="500" src="https://app.powerbi.com/view?r=eyJrIjoiN2E3MTE5Y2UtNWQ0MC00MGU0LWE3YjUtY2RhMmU0MDZjMjEyIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>

     

    </>
  )
}


export const Indicateur = () => {
   //FOR MODAL TABLE BOU BAKH BI
   const [modalIsOpen, setIsOpen] = useState(false);
   const [activeTab, setActiveTab] = useState('structure');
  
   const openModal = () => {
     setIsOpen(true);
   };
  
   const closeModal = () => {
     setIsOpen(false);
   };

  const [isEducation, SetEducation] = React.useState(true);
  const [isSante, SetSanté] = React.useState(false);
  const HandleEducation = () => {
    SetEducation(true);
    SetSanté(false);
    console.log("test education");
  };
  const HandleSante = () => {
    SetSanté(true);
    SetEducation(false);
    console.log("test sante ");
  };

  return (
    <div style={{backgroundColor:"#195329"}}>
      <NavBar />
     
          <div className="arrow">
            <h3 style={{ fontWeight: "bold" }}>Sous domaine</h3>
          </div>

            <div  style={{display:"flex",flexDirection:"column",position:"fixed",left:"0px",top:"80px",gap:"5px"}} >
              <Button
                style={{
                  width:120,
                  border:"1px solid white",
                  backgroundColor: isEducation ? "#081B02" : "#195600",
                }}
                onClick={HandleEducation}
              >
                <img
                  alt="precvadi"
                  src={educicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5>Education</h5>
              </Button>
              <Button
                style={{
                  width:120,
                  border:"1px solid white",
                  backgroundColor: isSante ? "#081B02" : "#195600",
                }}
                type="button"
                onClick={HandleSante}
              >
                <img
                  alt="precvadi"
                  src={santeicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5>Sante</h5>
              </Button>
              <Button
              className='btnIcon1'
                style={{
                  borderColor: "#fff",
                  border: "1px solid #131C85",
                  backgroundColor:"white",
                  color:"black"
                }}
                type="button"
                onClick={openModal}
              >
                <img
                  alt="precvadi"
                  src={tableicon}
                  className="carouselicon"
                  fluid
                  rounded
                />
                <h5 className='titreES1' >tableau</h5>
                
              

              </Button>
            </div>
           
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  position: "relative",
                  top: "55%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  width: "90%",
                  padding: "10px",
                },
              }}
            >
              <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginBottom: "5px",
          borderBottom: activeTab === 'structure' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('structure')}
      >
        données par structure
      </span>
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 'communal' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('communal')}
      >
        données communale
      </span>

      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginBottom: "5px",
          marginLeft: "10px",
          borderBottom: activeTab === 's_structure' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('s_structure')}
      >
        santé structure
      </span>
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "#e7962d29",
          color: "#e7962ddb",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 's_communal' ? "2px solid #E7962D" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('s_communal')}
      >
        santé commune
      </span>

      
              <div>
               
                {activeTab == "structure" ? <Itableindicateur /> :
            activeTab == "communal" ? <Itableindicateur_2 />:
            activeTab == "s_structure" ? <S_Itableindicateur /> :
            activeTab == "s_communal" && <S_Itableindicateur_2 />
          }
              </div>

              <img
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={closeModal}
                alt="precvadi"
                src={fermerModal}
                title="fermer"
                fluid
                rounded
                width="30px"
                heigth="30px"
              />
            </Modal>
         
          {isEducation && <Education />}
          {isSante && <Sante />}
     
     
      
    </div>
  );
};
