import React , {useState,useEffect} from 'react'
import "../../css/Actualite.css"
import NavBar from "../NavBar";
import 'bootstrap/dist/css/bootstrap.min.css';
// import "../../css/GestionBlog.css"
import "../../css/ressources.css"

import { Container, Row, Col } from 'react-bootstrap';
const download=require("../images/upload.png");
const gif = require ("../images/updload.gif");
const PostRessource= () => {
	
	useEffect(() => {
        fetchBlog();
      }, []);
	const [blog,setBlog]=useState([])
  const [loading, setLoading] = useState(false);
	const fetchBlog = async () => {
        try {
          setLoading(true);
          const response = await fetch('https://precvadijoal-fadhiouth.sn/list-ressources'); 
          const data = await response.json();
          console.log(data);
          setBlog(data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
        finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      };
      const  DownloadRessource = async(fileurl)=> {
        console.log ("download file ");
        const downloadLink = document.createElement('a');
        const originalString = fileurl;
        const substringToDelete = ""
        const filecsv= originalString.replace(substringToDelete, "");
        downloadLink.href = fileurl;
        console.log(filecsv);
        downloadLink.href = "https://precvadijoal-fadhiouth.sn/"+filecsv
        downloadLink.download = 'form_data.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink); 
  
      }
	 
	  

	return (
		<div > 
			 
      <div className="containtable" > 
     
      <table className="new-table">

      <thead>
        <tr>
          <th>Titre </th>
          <th>Mots-clés</th>
          <th>Documents</th>
        </tr>
      </thead>
     
      <tbody>
      
        {blog.map((myblog) => (
          <tr key={myblog.id}>
            <td>{myblog.description}
            </td>
            <td> 
            {myblog.des}
            </td>
            <td>
            <div style={{backgroundColor:"white"}}  class="container bg-white">
        <div  class="col-md-12 text-center">
        <button   onClick={() => DownloadRessource(myblog.fileto)}  type="" class="btn btn-light"> 
          <img
          src={download} 
          height={20}
          alt="download this file "
          />
          </button>

        </div>
        </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={gif} alt="Loading" width="50" height="50" />
        </div>
      )}
      </div>
	</div>
	);
};









const  Ressources= () => {
  return (
    <> 
        <NavBar/>
		<Container fluid >
       
			<Row className="roomfac fontReg">
				<Col md={8} className="mb-4">
					<PostRessource />
				</Col>
				
			</Row>
           
		</Container>
        </>
    
  );
};

export default Ressources;






       








