
import React from 'react';
import NavBarConnexion from "../NavBarConnexion";
// import UserTable from './UserTable';
import TableUsers from './TableUsers';

const GestionUser = () => {
 

  return (
    <div>
       <NavBarConnexion/>
      <h1>Administration des utilisateurs </h1>
      <TableUsers/>
    </div>
  );

};

export default GestionUser;






 
