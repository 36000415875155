// import React , {useState,useEffect} from 'react'
// import Modal from "react-modal";
// import "../../css/Table.css"
// import "../../css/indicateurs.css"
// import axios from 'axios';

// const supprimer = require("../images/supprimer.png");
// const modifier= require("../images/update.png");



// const AjoutIndicateur =()=>{ 
//     const [structure,setStructure]=useState([])
//     const [newStructure, setNewStructure] = useState({ libelle :"" ,numerateur : "" ,denominateur:"", echelle:"",domaine:""  ,subdomain:"" ,niveauscolaire :""  });
//     const [isOpenAdd, setIsOpenAdd] = useState(false);
//     const [editedUser, setEditedUser] = useState(null);
//     const [isOpenEdit, setIsOpenEdit] = useState(false);

//   function toggleModal() {
//     setIsOpenAdd(!isOpenAdd);
//   }
//   function toggleModalEdit() {
//     setIsOpenEdit(!isOpenEdit);
//   }
//   function openEdit (current){ 
//     setEditedUser(current);
//     toggleModalEdit();

//  }
//   useEffect(() => {
//     fetchStructures();
//   }, []);

//   const handleUpdateUser = async (updatedUser)=>{ 

//     try {
//         const response = await axios.put(`https://precvadijoal-fadhiouth.sn/${updatedUser.id}`, updatedUser);
//         const updatedUserData = response.data;
//         setIsOpenEdit(!isOpenEdit);
//         console.log(updatedUserData)
//         fetchStructures();
//         // setUsers(users.map(user => user.id === parseInt (updatedUserData.id ) ? updatedUserData : user));
//         setEditedUser(null);
//       } catch (error) {
//         console.error('Error updating user:', error);
//       }
  
//   }
//   const handleDeleteUser =async(userId)=> { 
//     try {
//         await axios.delete(`https://precvadijoal-fadhiouth.sn/${userId}`);
//         setStructure(structure.filter(user => user.id !== userId));
        
//       } catch (error) {
//         console.error('Error deleting user:', error);
//       }
//   }
//   const fetchStructures = async () => {
//     try {
//       const response = await fetch('https://precvadijoal-fadhiouth.sn/list-all-indicateur'); 
//       const data = await response.json();
//       console.log(data);
//       setStructure(data);
//     } catch (error) {
//       console.error('Error fetching users:', error);
//     }
//   };

//   const handleCreateStructure = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post('https://precvadijoal-fadhiouth.sn/create-indicateur', newStructure);
//       console.log(response.data);
//       fetchStructures();
//     //   const createdUser = response.data;
//     //   setUsers([...users, createdUser])
//       setNewStructure({ libelle :"" ,numerateur : "" ,denominateur:"", echelle:"",domaine:""  ,subdomain:"" ,niveauscolaire :""  });
//       setIsOpenAdd(!isOpenAdd);
//     //   setIsSubmitted(true);
//       // Handle successful login, e.g., store token in local storage or state
//     } catch (error) {
//       console.error(error);
//     //   setErrorMessages({ name: "uname", message: errors.uname });`
//       // Handle error, e.g., display error message to the user
//     }
//   };
   
//   return ( 
//     <div className='tableWrap'>
//         <div> 
//            <button style={{backgroundColor:"#195329" }} onClick={toggleModal}>Ajouter un indicateur</button>
//            <Modal  isOpen={isOpenAdd}
//     onRequestClose={toggleModal} contentLabel="Ajouter indicateur  "
//     className="mymodal"
//     ariaHideApp={false}
//     overlayClassName="myoverlay"
//     closeTimeoutMS={500} >          

//            <form className="addForm" onSubmit={handleCreateStructure}> 
//             <div id="form-group"> 
//             <label id="name-label">Libelle indicateur</label> 
//             <input required  type="text" name="libelle" placeholder="" value={newStructure.libelle} onChange={(e) => setNewStructure({ ...newStructure, libelle: e.target.value })}/>
//               </div>
//               <div id="form-group"> 
//               <label  id="name-label"> Numerateur de l'indicateur </label> 
//             <input  required type="text"  name="numerateur"  placeholder="libelle numerateur " value={newStructure.numerateur} onChange={(e) => setNewStructure({ ...newStructure,numerateur: e.target.value })}/>
//             </div>
//             <div id="form-group"> 
//               <label  id="name-label"> Denominateur de l'indicateur </label> 
//             <input type="text"  name="denominateur"  placeholder="libelle denominateur " value={newStructure.denominateur} onChange={(e) => setNewStructure({ ...newStructure,denominateur: e.target.value })}/>
//             </div>
//             {/* <input   type="text"  name="roleName" placeholder="role" value={newUser.roleName} onChange={(e) => setNewUser({ ...newUser, roleName: e.target.value })}/> */}
//             <div id="form-group"> 
//             <label  id="name-label"> Echelle</label> 
//             <select  required value={newStructure.echelle}
//             onChange={(e) => setNewStructure({ ...newStructure, echelle: e.target.value })}> 
//               <option  value="" hidden>Selectionnez l'echelle</option>
//               <option value="communale">Communale</option>
//               <option value="structure">Structure</option>
//             </select>
//             </div>
//             <div id="form-group"> 
//             <label  id="name-label"> Domaine </label> 
//             <select  required value={newStructure.domaine}
//              onChange={(e) => setNewStructure({ ...newStructure,domaine: e.target.value })}> 
//               <option  value="" hidden>Selectionnez un domaine</option>
//               <option value="education">Education</option>
//               <option value="sante">Santé</option>
              
//             </select>
//             </div>
//             <div id="form-group"> 
//             <label  id="name-label"> Sous  Domaine </label> 
//               {
//   newStructure.domaine === "education" ? (

//          <select  required value={newStructure.subdomain}
//              onChange={(e) => setNewStructure({ ...newStructure, subdomain: e.target.value })}> 
//                  <option  value="" hidden>Selectionnez un sous domaine</option>
//               <option value="gouvernance">Gouvernance</option>
//               <option value="accepart">Acces et participation</option>
//               <option value="qualite">Qualité</option>
//               <option value="envscolaire">Environnement</option>
//               <option value="qualification">Qualification</option>
//               </select>
//   ) : (
//            <select  required value={newStructure.subdomain}
//              onChange={(e) => setNewStructure({ ...newStructure, subdomain: e.target.value })}> 
//               <option  value="" hidden>Selectionnez un sous domaine</option>
//                 <option value="gouvesante">Gouvernance Santé</option>
//                         <option value="depensesante">Depenses Santé</option>
//                         <option value="accessgeographique">Accessibilité géographique</option>
//                         <option value="ressourceshumaines">Ressources Humaines </option>
//                         <option value="santedelamere-dunouveau">Santé de la mere et du nouveau</option>
//                         <option value="mortalite">Mortalité</option>
//                         <option value="planificationfamiliale">Planification Familiale</option>
//                         <option value="vaccinationderoutinePEV">Vaccination de routine PEV</option>
//                         <option value="surviedelenfant">Survie de lEnfant</option>
//                         <option value="alimentationnutrition">Alimentation et nutrition</option>
//                         <option value="maladiesnontransmissible">Maladies non transmissibles</option>
//                         <option value="maladiestropicalesneglige">MTN Maladies Tropicales Négligés</option>
//                         <option value="tuberculose">Tuberculose</option>
//                         <option value="lepre">Lèpre</option>
//                         <option value="hepatite">Hépatite</option>
//                         <option value="vihsidaist">VIH SIDA IST</option>
//                         <option value="paludisme">Paludisme</option>
//                         <option value="disponibiliteservicesdebase">Disponibilité services de base</option>
//                         <option value="actionsociale">Action Sociale</option>
//                         <option value="wash">WASH</option>
//                         <option value="ansd">ANSD données attendues</option>
//                         </select>
//     )
//     }
             

           
          
         
//             </div>
//             {  newStructure.echelle === "communale" ? newStructure.niveauscolaire = "" : (
//                  <div id="form-group"> 
//                  <label  id="name-label"> Niveau Structure  </label> 
//                  {
//        newStructure.domaine === "education" ? (
//                  <select  required value={newStructure.niveauscolaire}
//                   onChange={(e) => setNewStructure({ ...newStructure, niveauscolaire: e.target.value })}> 
//                    <option  value="" hidden>Selectionnez le niveau</option>
//                    <option value="prescolaire">Prescolaire</option>
//                    <option value="elementaire">Elementaire</option>
//                   <option value="moyensecondaire">Moyen Secondaire</option>
//                   <option value="lycee">Lycee</option>
//                   </select>) : (
     
//              <select  required value={newStructure.niveauscolaire}
//              onChange={(e) => setNewStructure({ ...newStructure, niveauscolaire: e.target.value })}> 
               
//                   <option value="poste">Poste de Sante</option>
//                  <option value="hopital">Hopital</option>
//                  <option value="pharmacie">Pharmacie</option>
//                  </select>
//                   )}
//                  </div>

//             ) }
           
//             <div id="form-group"> 
//             <button className='deletebutton' onClick={toggleModal}>Annuler</button>
//             <button type="submit"  >Ajouter</button>
//             </div>
            

//         </form>
//            </Modal>
//            <Modal  isOpen={isOpenEdit}
//     onRequestClose={toggleModalEdit} contentLabel="Ajouter Utilisateur "
//     className="mymodal"
//     ariaHideApp={false}
//     overlayClassName="myoverlay"
//     closeTimeoutMS={500} > 
//       {editedUser && (
//              <form onSubmit={e => {
//                e.preventDefault();
//                handleUpdateUser(editedUser);
//              }}>
//                  <div id="form-group"> 
//             <label  id="name-label"> Libelle </label> 
//                <input required
//                  type="text"
//                  value={editedUser.libelle}
//                  onChange={(e) => setEditedUser({ ...editedUser, libelle: e.target.value })}
//                />
//                </div>
//                <div id="form-group"> 
//               <label  id="name-label"> Numerateur </label> 
//                <input required
//                  type="text"
//                  value={editedUser.numerateur}
//                  onChange={(e) => setEditedUser({ ...editedUser, numerateur: e.target.value })}
//                />
//                </div>

//                <div id="form-group"> 
//               <label  id="name-label"> Denominateur </label> 
//                <input 
//                  type="text"
//                  value={editedUser.denominateur}
//                  onChange={(e) => setEditedUser({ ...editedUser, denominateur: e.target.value })}
//                />
//                </div>
//                <div id="form-group"> 
//             <label  id="name-label"> Echelle </label> 
//                <select  required  value={editedUser.echelle}
//          onChange={(e) => setEditedUser({ ...editedUser, echelle: e.target.value })}> 
                        
//                         <option value="communale">Communale</option>
//                           <option value="structure">Structure</option>
//                      </select>
//                      </div>
//                      <div id="form-group"> 
//             <label  id="name-label"> Domaine </label> 
//                <select  required  value={editedUser.domaine}
//          onChange={(e) => setEditedUser({ ...editedUser, domaine: e.target.value })}> 
//                        <option value="education">Education</option>
//                         <option value="sante">Santé</option>
//                      </select>
//                      </div>
//                      <div id="form-group"> 
//             <label  id="name-label"> Sous Domaine </label> 
//             {
//        editedUser.domaine === "education" ? (
//                <select  required  value={editedUser.subdomain}
//          onChange={(e) => setEditedUser({ ...editedUser, subdomain: e.target.value })}> 
//                         <option value="gouvernance">Gouvernance</option>
//                         <option value="accepart">Acces et participation</option>
//                         <option value="qualite">Qualité</option>
//                         <option value="envscolaire">Environnement</option>
//                         </select>
//                         ): (
//                             <select  required  value={editedUser.subdomain}
//          onChange={(e) => setEditedUser({ ...editedUser, subdomain: e.target.value })}> 

//                         <option value="gouvesante">Gouvernance Santé</option>
//                         <option value="depensesante">Depenses Santé</option>
//                         <option value="accessgeographique">Accessibilité géographique</option>
//                         <option value="ressourceshumaines">Ressources Humaines </option>
//                         <option value="santedelamere-dunouveau">Santé de la mere et du nouveau</option>
//                         <option value="mortalite">Mortalité</option>
//                         <option value="planificationfamiliale">Planification Familiale</option>
//                         <option value="vaccinationderoutinePEV">Vaccination de routine PEV</option>
//                         <option value="surviedelenfant">Survie de lEnfant</option>
//                         <option value="alimentationnutrition">Alimentation et nutrition</option>
//                         <option value="maladiesnontransmissible">Maladies non transmissibles</option>
//                         <option value="maladiestropicalesneglige">MTN Maladies Tropicales Négligés</option>
//                         <option value="tuberculose">Tuberculose</option>
//                         <option value="lepre">Lèpre</option>
//                         <option value="hepatite">Hépatite</option>
//                         <option value="vihsidaist">VIH SIDA IST</option>
//                         <option value="paludisme">Paludisme</option>
//                         <option value="disponibiliteservicesdebase">Disponibilité services de base</option>
//                         <option value="actionsociale">Action Sociale</option>
//                         <option value="wash">WASH</option>
//                         <option value="ansd">ANSD données attendues</option>

//                      </select>)}
//                      </div>
//                      {  (editedUser.echelle === "communale" && editedUser.niveauscolaire !== "") ? (
//                      <div id="form-group"> 
//             <label  id="name-label"> Niveau Scolaire </label> 
//            { editedUser.domaine === "education" ? (
//                <select  required  value={editedUser.niveauscolaire}
//          onChange={(e) => setEditedUser({ ...editedUser, niveauscolaire: e.target.value })}> 
//                       <option hidden value="">Selectionnez le niveau </option> 
//                        <option value="prescolaire">Prescolaire</option>
//                       <option value="elementaire">Elementaire</option>
//                       <option value="moyensecondaire">Moyen Secondaire</option>
//                       <option value="lycee">Lycee</option>
                    
//                      </select>) : ( 
//                               <select  required  value={editedUser.niveauscolaire}
//          onChange={(e) => setEditedUser({ ...editedUser, niveauscolaire: e.target.value })}> 
//                         <option hidden value="">Selectionnez le niveau </option> 
//                       <option value="poste">Poste de Sante</option>
//                       <option value="hopital">Hopital</option>
//                       <option value="pharmacie">Pharmacie</option>
//                      </select>
//                      ) 
//                      }
//                      </div>
//                      ) : (<></>)}
//               {  (editedUser.echelle === "structure" ) ? (
//                      <div id="form-group"> 
//             <label  id="name-label"> Niveau Scolaire </label> 
//            { editedUser.domaine === "education" ? (
//                <select  required  value={editedUser.niveauscolaire}
//          onChange={(e) => setEditedUser({ ...editedUser, niveauscolaire: e.target.value })}> 
//                          <option hidden value="">Selectionnez le niveau </option> 
//                        <option value="prescolaire">Prescolaire</option>
//                       <option value="elementaire">Elementaire</option>
//                       <option value="moyensecondaire">Moyen Secondaire</option>
//                       <option value="lycee">Lycee</option>
                    
//                      </select>) : ( 
//                               <select  required  value={editedUser.niveauscolaire}
//          onChange={(e) => setEditedUser({ ...editedUser, niveauscolaire: e.target.value })}> 
//                         <option hidden value="">Selectionnez le niveau </option> 
//                       <option value="poste">Poste de Sante</option>
//                       <option value="hopital">Hopital</option>
//                       <option value="pharmacie">Pharmacie</option>
//                      </select>
//                      ) 
//                      }
//                      </div>
//                      ) : (<></>)}
//            <div id="form-group"> 
//                  <button  className='deletebutton' type="cancel">Annuler</button>
//                <button type="submit">Update</button>
               
//                </div>
//              </form>
            
//            )}

//     </Modal>
           
//        <table className="indic-table"> 
    
//         <thead> 
//             <tr> 
//             <th > Id</th>
//             <th> libelle</th>
//             <th> numerateur </th>
//             <th> denominateur</th>
//             <th> echelle</th>
//             <th> Domaine</th>
//             <th> Sous domaine</th>
//             <th> Niveau Scolaire</th>
//             <th>Actions </th>
//             </tr>

//         </thead>
//         <tbody>
//             {
//             structure.map((current)=> ( 
//              <tr key={current.id}> 
//                 <td> {current.id}</td>
//                 <td>{current.libelle}</td>
//                 <td>{current.numerateur}</td>
//                 <td>{current.denominateur}</td>
//                 <td>{current.echelle === "communale"  ? "Communale": "Structure" } </td>
//                 <td>{current.domaine === "education"  ? "Education": "Sante" } </td>
//                 <td>
//                     {
//                     current.subdomain === "gouvernance"  ? "Gouvernance":  
//                     current.subdomain==="accepart" ? "Acces et participation" :
//                      current.subdomain==="qualite" ? "Qualité" :
//                      current.subdomain==="envscolaire" ? "Environnement" :
//                      current.subdomain==="qualification" ? "Qualification":
//                      current.subdomain==="gouvesante" ? "Gouvernance Santé":
//                      current.subdomain==="depensesante" ? "Depenses Santé":
//                      current.subdomain==="accessgeographique" ? "Accessibilité géographique":
//                      current.subdomain==="ressourceshumaines" ? "Ressources Humaines":
//                      current.subdomain==="santedelamere-dunouveau" ? "Santé de la mere et du nouveau ":
//                      current.subdomain==="mortalite" ? "Mortalité ":
//                      current.subdomain==="planificationfamiliale" ? "Planification Familiale ":
//                      current.subdomain==="vaccinationderoutinePEV" ? "Vaccination de routine PEV":
//                      current.subdomain==="surviedelenfant" ? " Survie de lEnfant":
//                      current.subdomain==="alimentationnutrition" ? " Alimentation et nutrition":
//                      current.subdomain==="maladiesnontransmissible" ? "Maladies non transmissibles ":
//                      current.subdomain==="maladiestropicalesneglige" ? "MTN Maladies Tropicales Négligés ":
//                      current.subdomain==="tuberculose" ? "Tuberculose":
//                      current.subdomain==="lepre" ? "Lèpre ":
//                      current.subdomain==="hepatite" ? " Hépatite":
//                      current.subdomain==="vihsidaist" ? "VIH SIDA IST":
//                      current.subdomain==="paludisme" ? "Paludisme":
//                      current.subdomain==="disponibiliteservicesdebase" ? "Disponibilité services de base ":
//                      current.subdomain==="actionsociale" ? "Action Sociale ":
//                      current.subdomain==="wash" ? "WASH ": 
//                      current.subdomain==="ansd" ? "ASND données attendues ": ""
//                      } 
//                 </td>

//                  <td>{current.niveauscolaire === "prescolaire"  ? "Prescolaire": 
//                       current.niveauscolaire==="elementaire" ? "Elementaire" :
//                     current.niveauscolaire==="moyensecondaire" ? "Moyen Secondaire" :
//                     current.niveauscolaire==="lycee" ? "Lycee" :
//                     current.niveauscolaire==="poste" ? "Poste" :
//                     current.niveauscolaire==="hopital" ? "Hopital" :
//                     current.niveauscolaire==="Pharmacie" ? "Hopital"  : ""
//                  } </td>
//                  <td> 
//                     <button  className="editUser" onClick={() => openEdit(current) }>
//                         <img  height={18} width={20} alt ="" src={modifier} />
//                     </button>

//                  <button onClick={() => handleDeleteUser(current.id)}  className="deleteUser"> 
                   
//                     <img    height={18} width={20}  alt ="" src={supprimer} />
//                     </button> 
//                  </td>
//              </tr>
//               ))
//              }
//               </tbody>
//         </table>

      

//         </div>
//     </div>
// ) 
// }
// export default AjoutIndicateur; 


import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Button from 'react-bootstrap/Button';
import "../../css/Table.css";
import "../../css/indicateurs.css";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
// import { control } from "leaflet";
const supprimer = require("../images/supprimer.png")
const modifier = require("../images/update.png")
const introuvable = require("../images/introuvable.png");
const educicon=require("../images/educicon.png");
const santeicon = require ("../images/educsante.png");

const AjoutIndicateur = () => {
  const [structure, setStructure] = useState([]);
  const [newStructure, setNewStructure] = useState({
    libelle: "",
    numerateur: "",
    denominateur: "",
    echelle: "",
    domaine: "",
    subdomain: "",
    niveauscolaire: "",
  });
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  function toggleModal() {
    fetchStructures();
    setIsOpenAdd(!isOpenAdd);
  }
  function toggleModalEdit() {
    fetchStructures();
    setIsOpenEdit(!isOpenEdit);
  }
  function openEdit(current) {
    setEditedUser(current);
    toggleModalEdit();
  }
  useEffect(() => {
    fetchStructures();
  }, []);


  /***************************************************************************************
   * **************************filtrer les données******************************************
   * ***************************************************************************************/


  //pour remettre les valeurs au etat initiaux
  const [activelink, setActivelink] = useState(true)
  const [activelinksante, setActiveSantelink] = useState(true)
  const [controldomaine, setControldomaine] = useState("education")
  const [search, setSearch] = useState(false);
  const [filtres, setFiltres] = useState({
    echelle: "echelle",
    domaine: "Domaine",
    subdomain:"Sous domaine", 
    niveauscolaire: "Niveau Scolaire",
  });
 
  const echelle = ["echelle", "structure", "communale"];
  // const domaine = ["Domaine", "sante", "education"];
  const niveauscolaire=[
    "Niveau Scolaire",
    "elementaire",
    "lycee",
    "moyensecondaire",
    "prescolaire",
    "poste",
    "hopital",
    "pharmacie"
  ];
  const subdomain = [
    "Sous domaine",
    "gouvesante",
    "depensesante",
    "accessgeographique",
    "ressourceshumaines",
    "santedelamere-dunouveau",
    "mortalite",
    "planificationfamiliale",
    "vaccinationderoutinePEV",
    "surviedelenfant",
    "alimentationnutrition",
    "maladiesnontransmissible",
    "maladiestropicalesneglige",
    "tuberculose",
    "lepre",
    "hepatite",
    "vihsidaist",
    "paludisme",
    "disponibiliteservicesdebase",
    "actionsociale",
    "wash",
    "ansd",
    "qualification",
    "gouvernance",
    "qualite",
    "envscolaire",
    "accepart"

  ];
 
  const handleFiltresChange = (type, value) => {
    setFiltres({ ...filtres, [type]: value });
    setSearch(true);
  };
  //Filtrer
 /* const handleFiltresChange = (type, value) => {
    let updatedNiveauxScolaires =  init_niveauscolaire;
   console.log(type + " " + value)
  if (type === "domaine" && value === "education") {
    // Si le domaine est "education", exclure "poste" de la liste des niveaux scolaires
    setNiveauscolaire(init_niveauscolaire)
    updatedNiveauxScolaires = updatedNiveauxScolaires.filter(niveau =>  ["elementaire", "prescolaire", "lycee", "moyensecondaire"].includes(niveau));
  }
   if (type === "domaine" && value === "sante") {
    // Si le domaine est "sante", réintégrer "poste" dans la liste des niveaux scolaires
    setNiveauscolaire(init_niveauscolaire)
    updatedNiveauxScolaires = niveauscolaire.filter(niveau => ["poste", "hopital", "pharmacie"].includes(niveau));
  }
 

  setFiltres({ ...filtres, [type]: value });
  //setNiveauscolaire(updatedNiveauxScolaires); // Mettez à jour le tableau des niveaux scolaires
  setSearch(true);
  }; */

  const filtrerStructure = () => {
    return structure.filter(
      (structure) =>
        (filtres.echelle === "echelle" ||
          structure.echelle === filtres.echelle) &&
        (filtres.domaine === "Domaine" ||
          structure.domaine === filtres.domaine) &&
          (filtres.subdomain === "Sous domaine" ||
          structure.subdomain === filtres.subdomain) &&
        (filtres.niveauscolaire === "Niveau Scolaire" ||
          structure.niveauscolaire === filtres.niveauscolaire)
    );
  };
 /***** POUR PASSER DE SANTE à EDUCATION OU LE CONTRAIRE  *****/
  async function mis_a_jour(choix){
    setSearch(false)
    setLoading(true)
    setStructure([]);
    setFiltres(
      {
        echelle: "echelle",
        domaine: "Domaine",
        subdomain:"Sous domaine", 
        niveauscolaire: "Niveau Scolaire",
      }
    )
    setControldomaine(choix)
    try {
      const response = await fetch("https://precvadijoal-fadhiouth.sn/list-all-indicateur");
      const data = await response.json();
     
      
      //if (filterDomaine === "") {
      setStructure(data.filter((user) => user.domaine === choix));
      setLoading(false);
      //} else {
      //  setStructure(data.filter((user) => user.domaine === filterDomaine));
      //  setLoading(false)
      // }
     if (choix === "sante"){  
      setActiveSantelink(true); 
      setActivelink(false); 
    } 
     if (choix === "education") { 
      setActivelink(true); 
      setActiveSantelink(false); 
    }
    } catch (error) {
      console.error("Error fetching users:", error);
    }

  }


  const StructureFiltres = filtrerStructure();
  const handleUpdateUser = async (updatedUser) => {
    try {
      const response = await axios.put(
        `https://precvadijoal-fadhiouth.sn/update-indicateur/${updatedUser.id}`,
        updatedUser
      );
      const updatedUserData = response.data;
      setIsOpenEdit(!isOpenEdit);
      console.log(updatedUserData);
      fetchStructures();
      // setUsers(users.map(user => user.id === parseInt (updatedUserData.id ) ? updatedUserData : user));
      setEditedUser(null);
      Swal.fire({
        title: "Reussie !",
        text: "L'élément a été Modifié.",
        icon: "success",
        timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
        timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
      });
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleDeleteUser = async (userId) => {
    Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer cet élément ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
      
    }).then( async (result) =>  {
      if (result.isConfirmed) {
        try {
          //await axios.delete(`https://precvadijoal-fadhiouth.sn/delete-indicateur/${userId}`);
          setStructure(structure.filter((user) => user.id !== userId));
        } catch (error) {
          console.error("Error deleting user:", error);
        }

        // Afficher une alerte de succès après la suppression (vous pouvez personnaliser cela)
        Swal.fire({
          title: "Supprimé !",
          text: "L'élément a été supprimé.",
          icon: "success",
          timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
          timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
        });
      }
    });
  
  };
  const fetchStructures = async () => {
    try {
      const response = await fetch("https://precvadijoal-fadhiouth.sn/list-all-indicateur");
      const data = await response.json();
     
      
      //if (filterDomaine === "") {
      setStructure(data.filter((user) => user.domaine === "education"));
      setLoading(false);
      //} else {
      //  setStructure(data.filter((user) => user.domaine === filterDomaine));
      //  setLoading(false)
      // }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleCreateStructure = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://precvadijoal-fadhiouth.sn/create-indicateur",
        newStructure
      );
      console.log(response.data);

      //   const createdUser = response.data;
      //   setUsers([...users, createdUser])
      setNewStructure({
        libelle: "",
        numerateur: "",
        denominateur: "",
        echelle: "",
        domaine: "",
        subdomain: "",
        niveauscolaire: "",
      });
      setIsOpenAdd(!isOpenAdd);
      //   setIsSubmitted(true);
      // Handle successful login, e.g., store token in local storage or state
      Swal.fire({
        title: "Reussie !",
        text: "L'élément a été Ajouté.",
        icon: "success",
        timer: 3000, // La modal de succès se fermera automatiquement après 3 secondes (ajustez selon vos besoins)
        timerProgressBar: true, // Afficher une barre de progression pour le compte à rebours
      });
    } catch (error) {
      console.error(error);
      //   setErrorMessages({ name: "uname", message: errors.uname });`
      // Handle error, e.g., display error message to the user
    }
  };

  return (
    <div className="tableWrap">
      <div>
       
        <button style={{ backgroundColor: "#195329",color:"white",border:"none",borderRadius:"7px",marginTop:"5px" }} onClick={toggleModal}>
          Ajouter un indicateur
        </button>
        <div style={{float:"right"}} >
        <div className="d-flex" >
        <div className="card">  
                  <Button style={{ width:130, backgroundColor: activelinksante ?  "#195600" : "#081b02"  }}  type="button"  onClick={()=>(mis_a_jour('sante'))}>  
                  <img
                alt="precvadi"
                src={santeicon}
                className="carouselicon"
                fluid
                rounded
              />
              <h5>Sante</h5>
                  </Button>
                 
          </div> 
          <div className="card">  
                  <Button style={{ width:130,backgroundColor: activelink ?  "#195600" : "#081b02" }}  onClick={()=>(mis_a_jour('education'))}> 
                  <img
                alt="precvadi"
                src={educicon}
                className="carouselicon"
                fluid
                rounded
              />
              <h5>Education</h5>
                     </Button>
                 
             </div> 
        {/* <div style={{opacity : !activelink ? 0.3 : 1}}  className="navigation_indicateur" onClick={()=>(mis_a_jour('education'))}>
            éducation
        </div>
        <div style={{opacity : activelink ? 0.3 : 1}} className="navigation_indicateur"  onClick={()=>(mis_a_jour('sante'))}>
           santé
        </div> */}
        </div>
        </div>

        <Modal
          isOpen={isOpenAdd}
          onRequestClose={toggleModal}
          contentLabel="Ajouter indicateur  "
          className="mymodal"
          ariaHideApp={false}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <form className="addForm" onSubmit={handleCreateStructure}>
            <div id="form-group">
              <label id="name-label">Libelle indicateur</label>
              <input
                required
                type="text"
                name="libelle"
                placeholder=""
                value={newStructure.libelle}
                onChange={(e) =>
                  setNewStructure({ ...newStructure, libelle: e.target.value })
                }
              />
            </div>
            <div id="form-group">
              <label id="name-label"> Numerateur de l'indicateur </label>
              <input
                required
                type="text"
                name="numerateur"
                placeholder="libelle numerateur "
                value={newStructure.numerateur}
                onChange={(e) =>
                  setNewStructure({
                    ...newStructure,
                    numerateur: e.target.value,
                  })
                }
              />
            </div>
            <div id="form-group">
              <label id="name-label"> Denominateur de l'indicateur </label>
              <input
                type="text"
                name="denominateur"
                placeholder="libelle denominateur "
                value={newStructure.denominateur}
                onChange={(e) =>
                  setNewStructure({
                    ...newStructure,
                    denominateur: e.target.value,
                  })
                }
              />
            </div>
            {/* <input   type="text"  name="roleName" placeholder="role" value={newUser.roleName} onChange={(e) => setNewUser({ ...newUser, roleName: e.target.value })}/> */}
            <div id="form-group">
              <label id="name-label"> Echelle</label>
              <select
                required
                value={newStructure.echelle}
                onChange={(e) =>
                  setNewStructure({ ...newStructure, echelle: e.target.value })
                }
              >
                <option value="" hidden>
                  Selectionnez l'echelle
                </option>
                <option value="communale">Communale</option>
                <option value="structure">Structure</option>
              </select>
            </div>
            <div id="form-group">
              <label id="name-label"> Domaine </label>
              <select
                required
                value={newStructure.domaine}
                onChange={(e) =>
                  setNewStructure({ ...newStructure, domaine: e.target.value })
                }
              >
                <option value="" hidden>
                  Selectionnez un domaine
                </option>
                <option value="education">Education</option>
                <option value="sante">Santé</option>
              </select>
            </div>
            <div id="form-group">
              <label id="name-label"> Sous Domaine </label>
              {newStructure.domaine === "education" ? (
                <select
                  required
                  value={newStructure.subdomain}
                  onChange={(e) =>
                    setNewStructure({
                      ...newStructure,
                      subdomain: e.target.value,
                    })
                  }
                >
                  <option value="" hidden>
                    Selectionnez un sous domaine
                  </option>
                  <option value="gouvernance">Gouvernance</option>
                  <option value="accepart">Acces et participation</option>
                  <option value="qualite">Qualité</option>
                  <option value="envscolaire">Environnement</option>
                  <option value="qualification">Qualification</option>
                </select>
              ) : (
                <select
                  required
                  value={newStructure.subdomain}
                  onChange={(e) =>
                    setNewStructure({
                      ...newStructure,
                      subdomain: e.target.value,
                    })
                  }
                >
                  <option value="" hidden>
                    Selectionnez un sous domaine
                  </option>
                  <option value="gouvesante">Gouvernance Santé</option>
                  <option value="depensesante">Depenses Santé</option>
                  <option value="accessgeographique">
                    Accessibilité géographique
                  </option>
                  <option value="ressourceshumaines">
                    Ressources Humaines{" "}
                  </option>
                  <option value="santedelamere-dunouveau">
                    Santé de la mere et du nouveau
                  </option>
                  <option value="mortalite">Mortalité</option>
                  <option value="planificationfamiliale">
                    Planification Familiale
                  </option>
                  <option value="vaccinationderoutinePEV">
                    Vaccination de routine PEV
                  </option>
                  <option value="surviedelenfant">Survie de lEnfant</option>
                  <option value="alimentationnutrition">
                    Alimentation et nutrition
                  </option>
                  <option value="maladiesnontransmissible">
                    Maladies non transmissibles
                  </option>
                  <option value="maladiestropicalesneglige">
                    MTN Maladies Tropicales Négligés
                  </option>
                  <option value="tuberculose">Tuberculose</option>
                  <option value="lepre">Lèpre</option>
                  <option value="hepatite">Hépatite</option>
                  <option value="vihsidaist">VIH SIDA IST</option>
                  <option value="paludisme">Paludisme</option>
                  <option value="disponibiliteservicesdebase">
                    Disponibilité services de base
                  </option>
                  <option value="actionsociale">Action Sociale</option>
                  <option value="wash">WASH</option>
                  <option value="ansd">ANSD données attendues</option>
                </select>
              )}
            </div>

            <div id="form-group">
              <label id="name-label"> Niveau Structure </label>
              {newStructure.domaine === "education" ? (
                <select
                  required
                  value={newStructure.niveauscolaire}
                  onChange={(e) =>
                    setNewStructure({
                      ...newStructure,
                      niveauscolaire: e.target.value,
                    })
                    
                  }
                  
                >
                  <option value="" hidden>
                    Selectionnez le niveau
                  </option>
                  <option value="prescolaire">Prescolaire</option>
                  <option value="elementaire">Elementaire</option>
                  <option value="moyensecondaire">Moyen Secondaire</option>
                  <option value="lycee">Lycee</option>
                </select>
              ) : (
                <select
                  required
                  value={newStructure.niveauscolaire}
                  onChange={(e) =>
                    setNewStructure({
                      ...newStructure,
                      niveauscolaire: e.target.value,
                    })
                  }
                >
                  <option value="" hidden>
                    Selectionnez le niveau
                  </option>
                  <option value="poste">Poste de Sante</option>
                  <option value="hopital">Hopital</option>
                  <option value="pharmacie">Pharmacie</option>
                </select>
              )}
            </div>

            <div id="form-group">
              <button button className="deletebutton" type="reset" onClick={toggleModal}>
                Annuler
              </button>
              <button type="submit" >
                Ajouter
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={isOpenEdit}
          onRequestClose={toggleModalEdit}
          contentLabel="Ajouter Utilisateur "
          className="mymodal"
          ariaHideApp={false}
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          {editedUser && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateUser(editedUser);
              }}
            >
              <div id="form-group">
                <label id="name-label"> Libelle </label>
                <input
                  required
                  type="text"
                  value={editedUser.libelle}
                  onChange={(e) =>
                    setEditedUser({ ...editedUser, libelle: e.target.value })
                  }
                />
              </div>
              <div id="form-group">
                <label id="name-label"> Numerateur </label>
                <input
                  required
                  type="text"
                  value={editedUser.numerateur}
                  onChange={(e) =>
                    setEditedUser({ ...editedUser, numerateur: e.target.value })
                  }
                />
              </div>

              <div id="form-group">
                <label id="name-label"> Denominateur </label>
                <input
                  type="text"
                  value={editedUser.denominateur}
                  onChange={(e) =>
                    setEditedUser({
                      ...editedUser,
                      denominateur: e.target.value,
                    })
                  }
                />
              </div>
              <div id="form-group">
                <label id="name-label"> Echelle </label>
                <select
                  required
                  value={editedUser.echelle}
                  onChange={(e) =>
                    setEditedUser({ ...editedUser, echelle: e.target.value })
                  }
                >
                  <option value="communale">Communale</option>
                  <option value="structure">Structure</option>
                </select>
              </div>
              <div id="form-group">
                <label id="name-label"> Domaine </label>
                <select
                  required
                  value={editedUser.domaine}
                  onChange={(e) =>
                    setEditedUser({ ...editedUser, domaine: e.target.value })
                  }
                >
                  <option value="education">Education</option>
                  <option value="sante">Santé</option>
                </select>
              </div>
              <div id="form-group">
                <label id="name-label"> Sous Domaine </label>
                {editedUser.domaine === "education" ? (
                  <select
                    required
                    value={editedUser.subdomain}
                    onChange={(e) =>
                      setEditedUser({
                        ...editedUser,
                        subdomain: e.target.value,
                      })
                    }
                  >
                    <option value="gouvernance">Gouvernance</option>
                    <option value="accepart">Acces et participation</option>
                    <option value="qualite">Qualité</option>
                    <option value="envscolaire">Environnement</option>
                    <option value="qualification">Qualification</option>
                  </select>
                ) : (
                  <select
                    required
                    value={editedUser.subdomain}
                    onChange={(e) =>
                      setEditedUser({
                        ...editedUser,
                        subdomain: e.target.value,
                      })
                    }
                  >
                    <option value="gouvesante">Gouvernance Santé</option>
                    <option value="depensesante">Depenses Santé</option>
                    <option value="accessgeographique">
                      Accessibilité géographique
                    </option>
                    <option value="ressourceshumaines">
                      Ressources Humaines{" "}
                    </option>
                    <option value="santedelamere-dunouveau">
                      Santé de la mere et du nouveau
                    </option>
                    <option value="mortalite">Mortalité</option>
                    <option value="planificationfamiliale">
                      Planification Familiale
                    </option>
                    <option value="vaccinationderoutinePEV">
                      Vaccination de routine PEV
                    </option>
                    <option value="surviedelenfant">Survie de lEnfant</option>
                    <option value="alimentationnutrition">
                      Alimentation et nutrition
                    </option>
                    <option value="maladiesnontransmissible">
                      Maladies non transmissibles
                    </option>
                    <option value="maladiestropicalesneglige">
                      MTN Maladies Tropicales Négligés
                    </option>
                    <option value="tuberculose">Tuberculose</option>
                    <option value="lepre">Lèpre</option>
                    <option value="hepatite">Hépatite</option>
                    <option value="vihsidaist">VIH SIDA IST</option>
                    <option value="paludisme">Paludisme</option>
                    <option value="disponibiliteservicesdebase">
                      Disponibilité services de base
                    </option>
                    <option value="actionsociale">Action Sociale</option>
                    <option value="wash">WASH</option>
                    <option value="ansd">ANSD données attendues</option>
                  </select>
                )}
              </div>

              <div id="form-group">
                <label id="name-label"> Niveau Scolaire </label>
                {editedUser.domaine === "education" ? (
                  <select
                    required
                    value={editedUser.niveauscolaire}
                    onChange={(e) =>
                      setEditedUser({
                        ...editedUser,
                        niveauscolaire: e.target.value,
                      })
                    }
                  >
                    <option hidden value="">
                      Selectionnez le niveau{" "}
                    </option>
                    <option value="prescolaire">Prescolaire</option>
                    <option value="elementaire">Elementaire</option>
                    <option value="moyensecondaire">Moyen Secondaire</option>
                    <option value="lycee">Lycee</option>
                  </select>
                ) : (
                  <select
                    required
                    value={editedUser.niveauscolaire}
                    onChange={(e) =>
                      setEditedUser({
                        ...editedUser,
                        niveauscolaire: e.target.value,
                      })
                    }
                  >
                    <option hidden value="">
                      Selectionnez le niveau{" "}
                    </option>
                    <option value="poste">Poste de Sante</option>
                    <option value="hopital">Hopital</option>
                    <option value="pharmacie">Pharmacie</option>
                  </select>
                )}
              </div>

              <div id="form-group">
              <span   style={{
                  width: "50%",
                  height: "6vh",
                  outline: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#bb2d3b",
                  color: "white",
                }} className="deletebutton" onClick={toggleModalEdit} >
                  Annuler
                </span>
                <button type="submit">Modifier</button>
              </div>
            </form>
          )}
        </Modal>
            
        <table className="indic-table">
          <thead>
            <tr>
              <th> Id</th>
              <th> libelle</th>
              <th> numerateur </th>
              <th> denominateur</th>
              <th>
                <select
                  className="myStyle"
                  value={filtres.echelle}
                  onChange={(e) =>
                    handleFiltresChange("echelle", e.target.value)
                  }
                  style={{backgroundColor: filtres.echelle !== "echelle" && "#195329",
                  color: filtres.echelle !== "echelle" && "white",
                }}
                >
                  {echelle.map((echelle) => (
                    <option key={echelle} value={echelle}>
                      {echelle}
                    </option>
                  ))}
                </select>
              </th>
              <th>
 
        {/* <select
                  className="myStyle"
                  value={filtres.domaine}
                  onChange={(e) => {
                    handleFiltresChange("domaine", e.target.value)
                    setControldomaine(e.target.value)
                  }
                  
                  }
                >
                  {domaine.map((domaine) => (
                    <option key={domaine} value={domaine}>
                      {domaine}
                      
                    </option>
                  ))}
                  </select> */}
                  Domaine
              </th>
              <th>
              <select
              
                  className="myStyle"
                  value={filtres.subdomain}
                  onChange={(e) =>
                    handleFiltresChange("subdomain", e.target.value)
                  }
                  style={{backgroundColor: filtres.subdomain !== "Sous domaine" && "#195329",
                  color: filtres.subdomain !== "Sous domaine" && "white",}}
                >
                  {
                  controldomaine === "sante" ? 
                  <>
                  <option value="Sous domaine" >Sous domaine</option>
                  <option value="gouvesante" >gouvernance Santé</option>
                  <option value="depensesante" >Dépenses santé</option>
                  <option value="santedelamere-dunouveau" >Santé de la mer</option>
                  <option value="mortalite" >Mortalité</option>
                  <option value="planificationfamiliale" >Planification familiale</option>
                  <option value="vaccinationderoutinePEV" >Vaccination du routine PEV</option>
                  <option value="surviedelenfant" >Survie de l'enfant</option>
                  <option value="alimentationnutrition" >Alimentation et nutrition</option>
                  <option value="maladiesnontransmissible" >Maladie non transmissible</option>
                  <option value="maladiestropicalesneglige" >Maladies Tropicales Négligés</option>
                  <option value="tuberculose" >Tuberculose</option>
                  <option value="lepre" >Lèpre</option>
                  <option value="hepatite" >Hépatite</option>
                  <option value="vihsidaist" >VIH SIDA IST</option>
                  <option value="ansd" >ASND données attendues</option>
                  <option value="wash" >WASH</option>
                
                  </> : 
                  controldomaine === "education" ? 
                  <>
                  <option value="Sous domaine" >Sous domaine</option>
                  <option value="qualification" >qualification</option>
                  <option value="gouvernance" >Gouvernance</option>
                  <option value="qualite" >Qualité</option>
                  <option value="envscolaire" >Environnement Scolaire</option>
                  <option value="accepart" >Acces et participation</option>
                  
                  </>:
                  
                  
                  subdomain.map((subdomain) => (
                    <option  key={subdomain} value={subdomain}>
                      {
                         subdomain === "gouvernance"
                         ? "Gouvernance"
                         : subdomain === "accepart"
                         ? "Acces et participation"
                         : subdomain === "qualite"
                         ? "Qualité"
                         : subdomain === "envscolaire"
                         ? "Environnement Scolaire"
                         : subdomain === "qualification"
                         ? "Qualification"
                         : subdomain === "gouvesante"
                         ? "Gouvernance Santé"
                         : subdomain === "depensesante"
                         ? "Depenses Santé"
                         : subdomain === "accessgeographique"
                         ? "Accessibilité géographique"
                         : subdomain === "ressourceshumaines"
                         ? "Ressources Humaines"
                         : subdomain === "santedelamere-dunouveau"
                         ? "Santé de la mere et du nouveau "
                         : subdomain === "mortalite"
                         ? "Mortalité "
                         : subdomain === "planificationfamiliale"
                         ? "Planification Familiale "
                         : subdomain === "vaccinationderoutinePEV"
                         ? "Vaccination de routine PEV"
                         : subdomain === "surviedelenfant"
                         ? " Survie de lEnfant"
                         : subdomain === "alimentationnutrition"
                         ? " Alimentation et nutrition"
                         : subdomain === "maladiesnontransmissible"
                         ? "Maladies non transmissibles "
                         : subdomain === "maladiestropicalesneglige"
                         ? "MTN Maladies Tropicales Négligés "
                         : subdomain === "tuberculose"
                         ? "Tuberculose"
                         : subdomain === "lepre"
                         ? "Lèpre "
                         : subdomain === "hepatite"
                         ? " Hépatite"
                         : subdomain === "vihsidaist"
                         ? "VIH SIDA IST"
                         : subdomain === "paludisme"
                         ? "Paludisme"
                         : subdomain === "disponibiliteservicesdebase"
                         ? "Disponibilité services de base "
                         : subdomain === "actionsociale"
                         ? "Action Sociale "
                         : subdomain === "wash"
                         ? "WASH "
                         : subdomain === "ansd"
                         ? "ASND données attendues "
                         : subdomain}
                      
                    </option>
                  ))}
                </select>
              </th>
              <th >
                <select
             
                  className="myStyle"
                  value={filtres.niveauscolaire}
                  onChange={(e) =>
                    handleFiltresChange("niveauscolaire", e.target.value)
                  }
                  style={{backgroundColor: filtres.niveauscolaire !== "Niveau Scolaire" && "#195329",
                  color: filtres.niveauscolaire !== "Niveau Scolaire" && "white",
                }}
                >
               
                  {
                    controldomaine === "sante" ? <> 
               
              
                   <option value="Niveau Scolaire" > Poste</option>
                  {/*  <option value="poste" >poste</option>*/}
                      <option value="pharmacie" >pharmacie</option>
                      <option value="hopital" >hopital</option></> :
                       controldomaine === "education" ? <> 
                       <option value="Niveau Scolaire" > Niveau Scolaire</option>
                       <option value="elementaire" >elementaire</option>
                       <option value="lycee" >lycee</option>
                       <option value="moyensecondaire" >moyen secondaire</option>
                        <option value="prescolaire" >prescolaire</option></> 
                      
                      :
                         niveauscolaire.map((niveauscolaire) => (
                          <option key={niveauscolaire} value={niveauscolaire}>
                            {  niveauscolaire === "moyensecondaire" ? "Moyen secondaire" : niveauscolaire}
                          </option>
                        ))
                  }
                </select>
              </th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {/* Chargement des données */}
            {
             
              (StructureFiltres.length === 0 && search === true ) && (
                <tr>
                  <td colSpan={9} rowSpan={2}>
                  <img
                alt="precvadi"
                src={introuvable}
                className="carouselicon"
                fluid
                rounded
                
              />
                  </td>
                </tr>
                
              )
            }
            {loading && (
              <tr>
                <td colSpan={9} rowSpan={2}>
                  <Spinner
                 
                    animation="border"
                    role="status"
                    style={{ color: "#258BAF" }}
                  >
                    
                  </Spinner>
                  <span style={{color:"grey", opacity:0.8}} >Chargement des données...</span>
                </td>
              </tr>
              
            )}
            {StructureFiltres.map((current) => (
              <tr key={current.id}>
                <td> {current.id}</td>
                <td>{current.libelle}</td>
                <td>{current.numerateur}</td>
                <td>{current.denominateur}</td>
                <td>
                  {current.echelle === "communale" ? "Communale" : "Structure"}{" "}
                </td>
                <td>
                  {current.domaine === "education" ? "Education" : "Sante"}{" "}
                </td>
                <td>
                  {current.subdomain === "gouvernance"
                    ? "Gouvernance"
                    : current.subdomain === "accepart"
                    ? "Acces et participation"
                    : current.subdomain === "qualite"
                    ? "Qualité"
                    : current.subdomain === "envscolaire"
                    ? "Environnement"
                    : current.subdomain === "qualification"
                    ? "Qualification"
                    : current.subdomain === "gouvesante"
                    ? "Gouvernance Santé"
                    : current.subdomain === "depensesante"
                    ? "Depenses Santé"
                    : current.subdomain === "accessgeographique"
                    ? "Accessibilité géographique"
                    : current.subdomain === "ressourceshumaines"
                    ? "Ressources Humaines"
                    : current.subdomain === "santedelamere-dunouveau"
                    ? "Santé de la mere et du nouveau "
                    : current.subdomain === "mortalite"
                    ? "Mortalité "
                    : current.subdomain === "planificationfamiliale"
                    ? "Planification Familiale "
                    : current.subdomain === "vaccinationderoutinePEV"
                    ? "Vaccination de routine PEV"
                    : current.subdomain === "surviedelenfant"
                    ? " Survie de lEnfant"
                    : current.subdomain === "alimentationnutrition"
                    ? " Alimentation et nutrition"
                    : current.subdomain === "maladiesnontransmissible"
                    ? "Maladies non transmissibles "
                    : current.subdomain === "maladiestropicalesneglige"
                    ? "MTN Maladies Tropicales Négligés "
                    : current.subdomain === "tuberculose"
                    ? "Tuberculose"
                    : current.subdomain === "lepre"
                    ? "Lèpre "
                    : current.subdomain === "hepatite"
                    ? " Hépatite"
                    : current.subdomain === "vihsidaist"
                    ? "VIH SIDA IST"
                    : current.subdomain === "paludisme"
                    ? "Paludisme"
                    : current.subdomain === "disponibiliteservicesdebase"
                    ? "Disponibilité services de base "
                    : current.subdomain === "actionsociale"
                    ? "Action Sociale "
                    : current.subdomain === "wash"
                    ? "WASH "
                    : current.subdomain === "ansd"
                    ? "ASND données attendues "
                    : current.subdomain}
                </td>

                <td>
                  {current.niveauscolaire === "prescolaire"
                    ? "Prescolaire"
                    : current.niveauscolaire === "elementaire"
                    ? "Elementaire"
                    : current.niveauscolaire === "moyensecondaire"
                    ? "Moyen Secondaire"
                    : current.niveauscolaire === "lycee"
                    ? "Lycee"
                    : current.niveauscolaire === "poste"
                    ? "Poste"
                    : current.niveauscolaire === "hopital"
                    ? "Hopital"
                    : current.niveauscolaire === "Pharmacie"
                    ? "Hopital"
                    : current.subdomain}{" "}
                </td>
                <td>
                  <button
                    className="editUser"
                    onClick={() => openEdit(current)}
                  >
                    <img height={18} width={20} alt="" src={modifier} />
                  </button>

                  <button
                    onClick={() => handleDeleteUser(current.id)}
                    className="deleteUser"
                  >
                    <img height={18} width={20} alt="" src={supprimer} />
                  </button>
                </td>
              </tr>
            ))}
            
          </tbody>
          
        </table>
      </div>
    </div>
  );
};
export default AjoutIndicateur;