import React from "react";
import axios from 'axios';
import "../../css/SignIn.css";
import { Redirect } from "react-router";
import { useState, useContext  } from "react";
import Alert from 'react-bootstrap/Alert';
import CryptoJS from 'crypto-js';


// import Registrer from "./Registrer";
// import { Navbar } from "react-bootstrap";
import NavBar from "../NavBar";
import { UserContext } from '../../../src/UserContext';
const cacher_eyes = require("../images/cacher_eyes.png");
const afficher_eyes = require("../images/afficher_eyes.png");
export const SignIn = () => {
  const [username, setUsername] = useState('');
  const { user,loginUser} = useContext(UserContext);
  const [password, setPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [mailtest, setMailtest] = useState("");
  const [passwordtype, setPasswordType] = useState("password");
  const errors = {
    uname: "connexion invalide",
   
  };



  const handleLogin = async (e) => {

    e.preventDefault();
  

    // Validation de l'email 
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(username)) {
      setMailtest('Veuillez saisir une adresse e-mail valide');
    }
    else{
    try {
      setMailtest('');
      const response = await axios.post('https://precvadijoal-fadhiouth.sn/login', { username, password } );
      console.log(response.data);
      loginUser(response.data)
      console.log(user)
      setIsSubmitted(true);
      // Handle successful login, e.g., store token in local storage or state
    } catch (error) {
      console.error(error);
      setErrorMessages({ name: "uname", message: errors.uname });
      setTimeout(() => {
        
      }, 3000);
      // Handle error, e.g., display error message to the user
    }
  }
  };




  const renderErrorMessage = (name) =>
    name === errorMessages.name && 
    (
      //<div className="error">{errorMessages.message}</div>
      <Alert style={{fontSize:"1.2rem",padding:"0.4rem"}} key="warning" variant="danger">
      {errorMessages.message}
    </Alert>
    );

    //l'option voir le mot de passe
const f_switchType = () =>{
  if (passwordtype === "password") {
    setPasswordType("text")
  }
  else{
    setPasswordType("password")
  }
}


  const renderForm = (
    <div className="Auth-form-container">
     
      <form className="Auth-form" onSubmit={handleLogin}>
        <div className="Auth-form-content">
       {renderErrorMessage("uname")}
          {/* <h3 className="Auth-form-title">Connexion</h3> */}
          <div className="">
            <h3 className="label"> Email</h3>
            <input
              type="text"
              name="uname"
              onChange={(e) => setUsername(e.target.value)}
              className="form-control mt-1"
              placeholder="Enter username"
              required

            />
           <h6 className="text-danger" style={{fontSize:"13px", textAlign:"left"}} >{mailtest}</h6>
          </div>
           
          <div className="form-group mt-3">
            {/* <label>Password</label> */}
            <div style={{position:"relative"}} >
            <h3 className="label"> Password</h3>
            <input
            
              name="pass"
              type={passwordtype}
              onChange={(e) =>
                setPassword(
                  CryptoJS.SHA256(e.target.value).toString(CryptoJS.enc.Hex)
                )
              }
              className="form-control mt-1"
              placeholder="Enter password"
              required
            />
            <div onClick={f_switchType} style={{cursor:"pointer",backgroundColor:"#195329",display:"flex",alignItems:"center",justifyContent:"center",position:"absolute",right:"0px",top:"26px",width:"40px",height:"42px",borderRadius:"0px 5px 5px 0px"}} >
              <img alt="voir / caché mot de passe" src={passwordtype === "password" ? cacher_eyes : afficher_eyes}  />
            </div>
            </div>
            {renderErrorMessage("pass")}
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-block colorbtn ">
              Se connecter
            </button>
          </div>
          
          {/* <p className="forgot-password text-right mt-2">
          Forgot <a href="#">password?</a>
        </p> */}
        </div>
      </form>
    </div>
  );

  return (
    <>
      <NavBar />
      <div className="containersearch" style={{backgroundColor:"rgb(11 48 20)"}}>
        <div>
          <h5 className="igt">
            {" "}
            PRECVADI : Plateforme de renforcement des chaines de valeurs de
            données infranationales{" "}
          </h5>
        </div>

        {/* <div className="div3"> */}
        {/* <input type="text" placeholder="Search.."></input> */}
        {/* </div> */}
      </div>

      <div className="app">
        <div className="login-form">
          <div className="title">Connexion</div>

          {isSubmitted ? <Redirect to="/admin" /> : renderForm}
        </div>
      </div>
    </>
  );
};

