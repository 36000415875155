// Posts.js

import React from "react";
import { Post1 } from "./PostBlog";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/GestionBlog.css"

import NavBarConnexion from "../NavBarConnexion";


const GestionBlog = () => {
	return (
        <> 
        <NavBarConnexion/>
		{/*<Container fluid >
       
			<Row className="roomfac fontReg">
				<Col md={8} className="mb-4">
					<Post1 />
				</Col>
				
				
			</Row> 
	</Container>*/}
		<Post1 />
        </>
	);
};

export default GestionBlog;
