import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';
// import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import "../../css/Newsletter.css";
// import { Container } from "react-bootstrap";
const success = require("../images/success_modal.png");
//Modale envoyé avec success
function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" style={{height:"140px",textAlign:"center"}}>
     
     <Alert key="success" variant="success" style={{height:"auto",paddingBottom:"40px"}}>
         
          <img
               src={success}
               height={60}
               width={60}
               alt="download this file "
          /><br/>
           <span>Méssage envoyé avec succès</span>
        </Alert>
    
    </Modal>
  );
}



const Newsletter = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [sms, setSms] = useState(null);
  // const [success, setSuccess] = useState(false);
  const [comment, setComment] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const isValidEmail = (value) => {
    // Utilisez une expression régulière pour valider l'adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  const handleSubscribe = async () => {
    setLoading(true)
    setError(null);
    
    //le message
    if (comment.trim() === '') {
      setLoading(false)
      setSms('Ce champs ne peut etre vide')
      setTimeout(() => {
        setSms('')
      }, 3000);
    }
    // Vérifiez si l'adresse e-mail est valide avant d'envoyer la requête
    if (!isValidEmail(email)) {
      setLoading(false);
      setError("Format e-mail invalide.");
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }
    if ((comment.trim() !== '') && (isValidEmail(email))) {
      
 
     // Utilisez votre identifiant de service et votre modèle Email.js ici
     emailjs.send(
      'service_jmravid',  //  identifiant de service
      'template_cp815cb',      //  identifiant de modèle
      {
      
        message: comment,
        from_name: email,
      },
      'mEV3ZwX7Qyj8dlC7P'  //  identifiant utilisateur (user_id)
    )
    .then(
      (response) => {
        console.log('E-mail envoyé avec succès:', response);
        setLoading(false);
        setModalShow(true)
        setTimeout(() => {
        setModalShow(false)
        }, 2000);
      },
      (error) => {
        console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
      }
    );

    // Réinitialisez les champs après l'envoi
    setComment('');
    setEmail('');
  };
}

  return (
    <>
    <div className="boxNewsletter">
  
    

      <div className="label_News" style={{width:`100%`}}>
      <p className="en-tête">Vous avez des questions ou des commentaires ? <br/>
      N'hésitez pas à nous envoyer un message en utilisant le formulaire ci-dessous.
       </p>
      </div>

      <input
      className="input_News"
       
        type="email"
        placeholder="Veuillez entrer votre adresse e-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
     
      <textarea
      className="textarea"
      value={comment}
          onfocus="clearText(this)" placeholder="Saisissez votre texte ici..."
          onChange={(e) => setComment(e.target.value)}
            rows="3"
          />
       {sms && <p style={{ color: "#321818" }}>{sms}</p>}
      {error && <p style={{ color: "#321818" }}>{error}</p>}
      {/* {success && <p style={{ color: "green" }}>Inscription réussie !</p>} */}
      
      
      {
        loading ?
        <button className="button_News" style={{opacity:'0.6',cursor:"no-drop"}} disabled>
        en cours ...
      </button>:
      <button className="button_News" onClick={handleSubscribe}>
      Envoyer
    </button>
      }
     
      </div>
      <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Newsletter;