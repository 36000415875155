import React from "react";
import NavBarConnexion from "../NavBarConnexion";
import { PostRessource } from "./PostBlog";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/GestionBlog.css"
import { Container, Row, Col } from 'react-bootstrap';





const  GestionRessource = () => {
  return (
    <> 
        <NavBarConnexion/>
		<Container fluid >
       
			<Row className="roomfac fontReg">
				<Col md={8} className="mb-4">
					<PostRessource />
				</Col>
				
				
			</Row>
           
		</Container>
        </>
    
    
  );
};

export default GestionRessource;

