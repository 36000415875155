import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const boulel1 = require("../images/montrollandsante1.jpeg");
const boulel2=require("../images/montrollandeducation.jpeg");
const boulel3 = require ("../images/montrollandsante1.jpeg");

export default class DemoCarousel extends Component {
    render() {
        return (
            <Carousel autoPlay={true}  infiniteLoop={true}  showStatus={false} showIndicators={false} showThumbs={false} transitionTime={1} swipeable={true} >
                <div>
                    <img src={boulel1} alt="" />
                </div>
                <div>
                    <img src={boulel2}  alt="" />
                </div>
                <div>
                    <img src={boulel3}  alt="" />
                </div>
            </Carousel>
        );
    }
}