import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Modal from "react-modal";
import "../../css/Itableindicateur.css";

const modifierModal = require("../images/modifierModal.png");
const supprimerModal = require("../images/supprimerModal.png");
const telechargerModal = require("../images/telechargerModal.png");
const chargement = require("../images/loadingcarre.gif");

Modal.setAppElement("#root"); // Important for accessibility

const Itableindicateur = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading_2, setLoading_2] = useState(true);
  const [filter, setFilter] = useState(""); // État pour la valeur du filtre
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [newValue, setNewValue] = useState("");
//warning
const warning_ = () =>{
  Swal.fire({
    icon: 'error',
    title: 'Erreur',
    text: 'Vous ne disposez pas de ces privilèges, veuillez vous connecter en tant qu\'administrateur',
    timer: 5000,
    timerProgressBar: true,
    confirmButtonText: "ok",
    didOpen: () => {
      Swal.showLoading();
    },
    willClose: () => {
      Swal.hideLoading();
    }
  });
  
}

  useEffect(() => {
    const fetchData = async () => {
      let headersList = {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        value_structure: 31,
      });

      let reqOptions = {
        url: "https://precvadijoal-fadhiouth.sn/insert-commune",
        method: "GET",
        headers: headersList,
        data: bodyContent,
      };

      try {
        let response = await axios.request(reqOptions);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous ne pourrez pas annuler cette action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      let headersList = {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url: `https://precvadijoal-fadhiouth.sn/insert-commune/${id}`,
        method: "DELETE",
        headers: headersList,
      };

      try {
        let response = await axios.request(reqOptions);
        console.log(response.data);
        // Mettre à jour les données après suppression
        setData(data.filter((item) => item.idfact !== id));
        Swal.fire("Supprimé!", "L'élément a été supprimé.", "success");
      } catch (error) {
        console.error("Error deleting data:", error);
        Swal.fire(
          "Erreur!",
          "Il y a eu un problème lors de la suppression.",
          "error",
        );
      }
    }
  };

  const handleOpenModal = (item) => {
    setCurrentItem(item);
    setNewValue(item.valueStructure);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentItem(null);
    setNewValue("");
  };

  const handleUpdate = async () => {
    
    const result = await Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Vous êtes sur le point de modifier cette valeur!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, modifier!",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed && currentItem) {
      setLoading_2(false);
      let headersList = {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        value_structure_2: newValue,
      });

      let reqOptions = {
        url: `https://precvadijoal-fadhiouth.sn/insert-commune/${currentItem.idfact}`,
        method: "PUT",
        headers: headersList,
        data: bodyContent,
      };

      try {
        let response = await axios.request(reqOptions);
        console.log(response.data);
        setLoading_2(true)
        // Mettre à jour les données après modification
        setData(
          data.map((item) =>
            item.idfact === currentItem.idfact
              ? { ...item, valueStructure: newValue }
              : item,
          ),
        );
        Swal.fire("Modifié!", "L'élément a été modifié.", "success");
        handleCloseModal();
      } catch (error) {
        console.error("Error updating data:", error);
        Swal.fire(
          "Erreur!",
          "Il y a eu un problème lors de la modification.",
          "error",
        );
      }
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["Indicateur", "École", "Année", "Value"]],
      body: filteredData.map((item) => [
        item.valeurIndicateur,
        item.niveauEcole,
        item.valeurAnnee,
        item.valueStructure,
      ]),
    });
    doc.save("indicateurs.pdf");
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };


  

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: 'Chargement des données en cours',
        text: 'Veuillez patienter',
        html: `<img src="${chargement}" />`,
        background: "#F1F2F3",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    } else if (!loading) {
      Swal.close();
    }
  }, [loading, data]);
  

  if (loading) {
    return <div style={{width:"10px",height:"50vh"}} ></div>;
  }

  // Fonction de normalisation pour supprimer les espaces et convertir en minuscules
  const normalizeString = (str) => {
    return str.replace(/\s+/g, "").toLowerCase();
  };

  const normalizedFilter = normalizeString(filter);

  const filteredData = data.filter(
    (item) =>
      normalizeString(item.valeurIndicateur).includes(normalizedFilter) ||
      normalizeString(item.niveauEcole).includes(normalizedFilter) ||
      normalizeString(item.valeurAnnee).includes(normalizedFilter) ||
      normalizeString(item.temps).includes(normalizedFilter) ||
      normalizeString(item.valueStructure.toString()).includes(
        normalizedFilter,
      ),
  );

  return (
    <div>
      <input
        style={{
          border: "1px solid black",
          borderRadius: "5px",
          height: "6vh",
          outline: "none",
          textAlign: "start",
          marginBottom: "5px",
          position: "absolute",
          right: "50px",
          top: "8px",
        }}
        type="text"
        placeholder="Filtrer les données"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <div style={{ height: "50vh", overflowY: "scroll" }}>
        {filteredData.length === 0 ? (
          <div>Pas de résultat</div>
        ) : (
          <table style={{ fontSize: "5px", marginTop: "10px" }}>
            <thead>
              <tr>
                <th>Indicateur</th>
                <th>École</th>
                <th>Année</th>
                <th className="date_">Inséré</th>
                <th>Structure</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td className="td">{item.valeurIndicateur}</td>
                  <td className="td">{item.niveauEcole}</td>
                  <td className="td">{item.valeurAnnee}</td>
                  <td className="td">{formatDate(item.temps)}</td>
                  <td className="td">{item.valueStructure}</td>
                  <td className="td td_action">
                    {
                      localStorage.getItem("role") === "1" ?
<>
                      <img
                      title="Modifier"
                      style={{ cursor: "pointer", marginRight: "4px" }}
                      onClick={() => handleOpenModal(item)}
                      alt="precvadi"
                      src={modifierModal}
                      fluid
                      rounded
                      width="25px"
                      height="25px"
                    />
                    <img
                      title="Supprimer"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(item.idfact)}
                      alt="precvadi"
                      src={supprimerModal}
                      fluid
                      rounded
                      width="25px"
                      height="25px"
                    />
</>
                    :
                    <>
                    <img
                    title="Modifier"
                    style={{ cursor: "pointer", marginRight: "4px" }}
                    onClick={() => warning_()}
                    alt="precvadi"
                    src={modifierModal}
                    fluid
                    rounded
                    width="25px"
                    height="25px"
                  />
                  <img
                    title="Supprimer"
                    style={{ cursor: "pointer" }}
                    onClick={() => warning_()}
                    alt="precvadi"
                    src={supprimerModal}
                    fluid
                    rounded
                    width="25px"
                    height="25px"
                  />
                  </>
                    }
                 
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <img
        style={{ cursor: "pointer", marginBottom: "10px" }}
        onClick={handleDownloadPDF}
        title="Télécharger"
        alt="precvadi"
        src={telechargerModal}
        fluid
        rounded
        width="60px"
        height="60px"
      />
      <span>Télécharger ici</span>

      <Modal
      
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Modifier la structure"
      >
        <div style={{display:"flex",gap:"10px",justifyContent:"center",alignItems:"center",marginTop:"20vh"}} >
        <h2>Modifier la valeur de la structure</h2>
        <input
          type="number"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
       {loading_2 ? <button class="btn btn-primary" onClick={handleUpdate}>Modifier</button> : <button disabled class="btn btn-primary" type="button" >
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...
</button> }
        <button class="btn btn-primary" onClick={handleCloseModal}>Annuler</button>
        </div>
      </Modal>
    </div>
  );
};

export default Itableindicateur;
